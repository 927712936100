import { graphql } from 'gatsby'
import React from 'react'
import { ArchiveContent } from 'starterComponents'
const Category = (props) => {
  const {
    data: {
      categories: { name },
      posts: { nodes },
    },

    uri,
  } = props
  return (
    <>
      <ArchiveContent posts={nodes} paginationPrefix={uri} name={name} />
    </>
  )
}

export default Category

export const pageQuery = graphql`
  query ($id: String!, $offset: Int!, $postsPerPage: Int!) {
    categories: wpCategory(id: { eq: $id }) {
      uri
      name
      description
      seo {
        title
        fullHead
      }
    }

    posts: allWpPost(
      filter: { categories: { nodes: { elemMatch: { id: { eq: $id } } } } }
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        ...PostTemplateFragment_starter
      }
    }
  }
`
